


























































































































import Vue, { PropOptions } from 'vue';

type BannerAlertProps = Blok & {
  title: string;
  subtitle: string;
  datetime: string;
  date: string;
  time: string;
  showDateAndTime: boolean;
  showTimer: boolean;
  showTimerOnlyOnDesktop: boolean;
  imageCount: 'no-image' | 'single-image' | 'two-images' | 'big-image';
  singleImage?: any;
  smImage1?: any;
  smImage2?: any;
  bigImage?: any;
  button: any;
};

const BANNER_CLOSED_FLAG = 'bannerAlertClosed';
const BANNER_DATA_KEY = 'bannerAlertData';

export default Vue.extend({
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<BannerAlertProps>,
  },
  data() {
    return {
      hydrated: false, // Track if the client is fully loaded
      isVisible: true,
      daysLeft: 0,
    };
  },
  mounted() {
    this.hydrated = true;
    this.checkBannerVisibility();
    this.calculateDaysLeft();
  },
  methods: {
    closeComponent() {
      this.isVisible = false;
      localStorage.setItem(BANNER_CLOSED_FLAG, 'true');
    },
    calculateDaysLeft() {
      const eventDate = new Date(this.blok.datetime);
      const today = new Date();
      const timeDiff = eventDate.getTime() - today.getTime();
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      this.daysLeft = daysDiff;
    },
    checkBannerVisibility() {
      const storedData = localStorage.getItem(BANNER_DATA_KEY);
      const isBannerClosed =
        localStorage.getItem(BANNER_CLOSED_FLAG) === 'true';

      const currentData = JSON.stringify(this.blok);

      if (storedData === currentData && isBannerClosed) {
        this.isVisible = false;
      } else {
        localStorage.setItem(BANNER_DATA_KEY, currentData);
        localStorage.setItem(BANNER_CLOSED_FLAG, 'false');
        this.isVisible = true;
        this.$nextTick(() => {
          setTimeout(() => {
            const banner = document.querySelector('.banner-alert');
            if (banner && this.isVisible) banner.classList.add('visible');
          }, 100);
        });
      }
    },
  },
});
